<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <p class="msg">邮箱可以用来找回密码，请正确填写</p>
    <input v-model="my_email" class="ipt" type="text" placeholder="请输入你的邮箱" @input="changes">
    <van-button
      type="danger"
      style="width: 92%; margin: 30px auto; background: #ff272c"
      block
      :disabled="isdisabled"
      @click.native="commits()"
    >确认</van-button>
  </div>
</template>

<script>
import commontop from '@/compontens/menu.vue'
import heads from '@/compontens/heads.vue'
import { commits_emits } from '@/api/my_user.js'
import { Toast } from 'vant'
import Cookies from 'js-cookie'

export default {
  components: {
    heads,
    commontop

  },
  data() {
    return {
      title_msg: '设置邮箱',
      isdisabled: true,
      my_email: '',
      isshow: false,
      showMore: true,
      agency_id: '',
      zkb_pro: '',
      isZkb: false
    }
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log(this.agency_id + this.isZkb + '-----------------')
  },
  methods: {
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    // 监听验证码值发生变化
    changes() {
      if (this.my_email) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    // 提交
    commits() {
      var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
      if (re.test(this.my_email)) {
        var params = {
          email: this.my_email
        }
        commits_emits(params).then(res => {
          if (res.errNo === 0) {
            Toast(res.result)
          } else {
            Toast(res.result)
          }
        })
      } else {
        Toast('格式不正确')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.msg {
  padding: 80px 0 0 35px;
  color: #8c8c8b;
  letter-spacing: 1px;
}
.ipt {
  border-bottom: #dbdbdb 1px solid;
  width: 686px;
  font-size: 25px;
  color: #484848;
  caret-color: #484848;
  padding: 87px 0 15px 0px;
  margin-left: 35px;
}
</style>
